<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row style="color: red" class="mt-6">
      <v-spacer/>
      <v-col sm="11" xl="9">
        <h1 style="color: red">Wartung</h1>
        <p class="mt-6">
          Aktuell finden am <strong>Portal Vermessungsunterlagen NRW</strong> Wartungsarbeiten statt.<br>
          Hinweise zur Dauer der Arbeiten können Sie dem Wartungshinweis auf der
          <router-link to="/">Startseite</router-link>
          entnehmen.
        </p>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {}
  },
}
</script>
